:root {
    --innactive-b-color:rgb(229, 80, 0);
    --active-b-color: rgb(240, 108, 0);
    --active-border-color: rgb(32, 33, 36);
    --innactive-border-color: rgb(65, 68, 74);
}

* {
    margin:0;
    padding: 0;
}

html, body {
    overflow: hidden;
    justify-content: center;
    align-items: center;
}

canvas{
    background-image: radial-gradient(circle, rgb(242, 203, 171), rgb(242, 134, 0));
}

.webgl {
    position: fixed;
    top:0;
    left: 0;
    outline: none;
}

.loading-bar {
    z-index: 10;
    position: absolute;
    top: 50%;
    width: 100%;
    height:2px;
    background: white;
    transform: scaleX(0);
    transition: transform 0.5s;
    will-change: transform;
}

.loading-bar.ended {
    transition: transform 1.5s ease-in-out;
}

.loading-text{
    z-index: 10;
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50% );
    color: white;
    transform: scaleX(0);
    transition: transform 1s;
    will-change: transform;
}

.center{
    z-index: 15;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.reticle{
    z-index: 15;
    display: flex;
    border: 3px solid;
    position: absolute;
    aspect-ratio: 1/1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50% );
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: rgb(139, 139, 139);
    border-color: white;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 4px 2px black;
}

.inner-reticle{
    z-index: 15;
    aspect-ratio: 1/1;
    width: 4px;
    height: 4px;
    background-color: white;
    margin: auto;
    border-radius: 50%;
}

.reticle.hidden{
    display: none;
}

.cart{
    position: absolute;
    display: flex;
    top: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    border: 2px solid;
    border-radius: 5px;
    border-color: var(--innactive-border-color);
    background-color: var(--innactive-b-color);
    z-index: 12;
    cursor: pointer;
}

.cart-icon{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 40px;
    height: 40px;
    color: var(--innactive-border-color);
}
.cart:hover{
    background-color: var(--active-b-color);
    border-color: var(--active-border-color);
}

.cart-icon:hover{
    color: grey;
}

.cart-count{
    position: relative;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    top: 4px;
    left: -1px;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    border: 1px solid;
    border-color: var(--active-border-color);
    background-color: red;
}

#cart-list{
    width: 100%;
}

.cart-number{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin: auto;
    color: white;
    font-weight: bold;
}

.cart-title-box{
    list-style-type: none;
}

.cart-title{
    padding-top: 10px;
    padding-left: 10px;
    margin:0;
    height: 50px;
}

.cart-content.active{
    display: flex;
    width: 520px;
    position: absolute;
    border-radius: 5px;
    z-index: 11;
    top: 20px;
    right: 20px;
    min-height: 50px;
    overflow-y: scroll;
    background-color: antiquewhite;
    max-height: 600px;
}

.cart-content{
    display: none;
}

#price-col {
    margin: auto 0;
}

#price-symbol{
    margin: auto 0;
}

.cart-item-q{
    padding-right: 5px;
}

.quantity-text{
    padding: 0.375rem 0.375rem;
}

.quantity-form{
    display: flex;
    flex-wrap: nowrap;
    width: 125px;
    margin: auto;
    padding: 0;
}

.quantity-buttons > .btn{
    margin: auto;
    border: 1px solid;
    border-color: black;
}

.quantity-row > .col-4 {
    padding:0;
}

.webgl.interactive{
    cursor: pointer;
}

.controls {
    display: none;
}

.controls.active {
    display: flex;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 11;
    background-color: antiquewhite;
    border-radius: 5px;
    padding: 10px;
}

.settings-icon{
    position: relative;
    width:30px;
    height:30px;
    top: 50%;
    left:50%;
    transform: translate(-50%,-50%);
}

.controls-icon {
    position: absolute;
    width: 50px;
    height: 50px;
    justify-content: center;
    bottom: 20px;
    right: 20px;
    z-index: 12;
    border: 2px solid;
    background-color: var(--innactive-b-color);
    border-radius: 5px;
    border-color: var(--innactive-border-color);
    cursor: pointer;
}

.controls-icon:hover {
    background-color: var(--active-b-color);
    border-color: var(--active-border-color);
}

#Fini > h2 {
    z-index:20;
}

#Fini{
    cursor: pointer;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    right: 100px;
    bottom: 20px;
    z-index:20;
    padding: 5px;    
    background-color: var(--active-b-color);
    border-radius: 5px;
    border: 1px solid;
    border-color: var(--active-border-color);
}